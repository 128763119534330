<template>
    <div id="layout">
      <router-view name="header"></router-view>
      <router-view class="content"></router-view>
      <router-view name="footer"></router-view>
    </div>
</template>

<script>
export default {
  name: 'DefaultLayout',
  data () {
    return {
    }
  }

}
</script>

<style lang="scss">
.content {
  margin-top: 20px;
  min-height: calc(115vh - 252px);
}
</style>
